/* eslint-disable unicorn/no-null */
import React from "react";
import { Helmet } from "react-helmet";
import "twin.macro";

import Layout from "../components/Layout";

const VirtualTour = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Encore PGH Virtual Tour</title>
        <link
          rel="canonical"
          href="https://encorepgh.com/virtual-tour"
        />
        <script
          src="//lcp360.cachefly.net/panoskin.min.js"
          defer
        />
        <script
          src="/tour.js"
          defer
        />
      </Helmet>

      <Layout title="virtual-tour">
        <div
          id="tour"
          tw="w-screen mx-auto my-0 min-h-[562px] relative pt-[8.5625rem] xl:(pt-[9.125rem])"
        />
      </Layout>
    </>
  );
};

export default VirtualTour;
